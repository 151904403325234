import { useEffect, useState } from 'react'
import {
  Checkbox, Form, Input, message, Space,
} from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import {
  getOTPForgotPassword,
  signInApi,
  verifyOTPForgotPassword,
} from '../../apis/authenticationApis'
import { KEYS, set } from '../../util/localStorage'
import Axios from '../../services/Axios'
import ModalChangePassword from './modalChangePassword'
import { DEFAULT_PASSWORD } from '../../constants/constant'
import ModalOTPInput from '../ForgotPasswordFlow/modalOTPInput'
import ModalChangeAndForgotPassword from '../ForgotPasswordFlow/modalChangeAndForgotPassword'

const specialAccount = ['superadmin']

const LoginPage = () => {
  console.log('login')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isOpenModalChangePassword, setIsOpenModalChangePassword] = useState(false)
  const [isOpenModalOtp, setIsOpenModalOtp] = useState(false)
  const [last4digit, setLast4digit] = useState(false)
  const [isResendable, setIsResendable] = useState(false)
  const [
    isOpenModalChangeAndForgotPassword,
    setIsOpenModalChangeAndForgotPassword,
  ] = useState(false)
  const [otp, setOtp] = useState('')
  const [dataForgotPassword, setDataForgotPassword] = useState(null)
  const [expired_at, setExpiredAt] = useState(null)

  const [form] = Form.useForm()

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`)
  }

  useEffect(() => {
    if (!isOpenModalOtp) {
      setLast4digit(false)
      setExpiredAt(null)
    }
  }, [isOpenModalOtp])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'username') {
      setUsername(value)
      if (!value) {
        form.setFields([
          {
            name: 'username',
            errors: ['Vui lòng nhập username!'],
          },
        ])
      } else {
        form.setFields([
          {
            name: 'username',
            errors: [],
          },
        ])
      }
    } else if (name === 'password') {
      setPassword(value)
    }
  }

  const getOTP = async () => {
    try {
      const res = await getOTPForgotPassword(username?.trim())
      if (res.data?.data?.phone_number) {
        setLast4digit(res.data?.data?.phone_number)
        setExpiredAt(res.data?.data?.expired_at)
        setIsOpenModalOtp(true)
        setIsResendable(false)
      } else {
        form.setFields([
          {
            name: 'username',
            errors: ['Vui lòng liên lạc với quản trị viên.'],
          },
        ])
      }
    } catch (err) {
      const error = err?.response?.data?.message
      let errorMessage = 'Username không tồn tại.'
      setIsOpenModalOtp(false)
      if (error.includes('Wait for two minutes before resending the code.')) {
        errorMessage = 'Vui lòng đợi chút khi thực hiện yêu cầu tiếp theo.'
      }
      form.setFields([
        {
          name: 'username',
          errors: [errorMessage],
        },
      ])
    }
  }

  const confirmOtp = async (otp_code) => {
    try {
      const res = await verifyOTPForgotPassword(username, otp_code)
      if (res?.data?.data) {
        setIsOpenModalChangeAndForgotPassword(true)
        setDataForgotPassword({
          username,
          otp_code: otp,
        })
      } else {
        message.error('Có lỗi xảy ra')
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
      if (errorMessage === 'The invalid OTP code') {
        message.error('Mã OTP không chính xác, vui lòng kiểm tra và nhập lại')
      } else if (errorMessage === 'The expired OTP code') {
        message.error('Mã OTP đã hết hiệu lực, vui lòng thử lại sau.')
        setTimeout(() => {
          setIsOpenModalOtp(false)
        }, 2000)
      } else {
        message.error('Đã có lỗi trong quá trình xử lý. Vui lòng thử lại sau!')
      }
    }
  }

  const handleForgotPassword = async (e) => {
    e.preventDefault()
    if (username) {
      getOTP()
    } else {
      form.setFields([
        {
          name: 'username',
          errors: ['Vui lòng nhập username!'],
        },
      ])
    }
  }

  const onSubmit = async () => {
    try {
      const res = await signInApi(username?.trim(), password)
      const accessToken = res?.data?.data?.access_token
      if (accessToken) {
        Axios.getInstance().setToken(accessToken)
        if (password === DEFAULT_PASSWORD) {
          setIsOpenModalChangePassword(true)
        } else {
          set(KEYS.TOKEN, accessToken)
          window.location = '/home'
        }
      } else {
        message.error('Đăng nhập không thành công')
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Forbidden') {
        message.error('Tài khoản bị vô hiệu hóa')
      } else if (err?.response?.data?.message?.includes('E10001')) {
        message.error('User không tồn tại')
      } else if (
        err?.response?.data?.message
        === 'E10004: Incorrect username or password'
      ) {
        message.error('Sai tên đăng nhập hoặc mật khẩu. Vui lòng kiểm tra')
      } else {
        message.error('Có lỗi khi đăng nhập')
      }
    }
  }

  const handleShowChangePasswordSuccesfully = () => {
    setIsOpenModalChangePassword(false)
    message.success(
      'Đổi mật khẩu thành công. Vui lòng đăng nhập lại vào hệ thống',
    )
    setUsername('')
    setPassword('')
    form.resetFields()
  }

  return (
    <div className="login-page">
      <div className="login-bg">
        <div className="popup-login">
          <div className="left-content">
            <img src="/images/login/left-img.png" alt="left-img" />
          </div>
          <div className="right-content">
            <div className="logo-header">
              <img src="/images/common/logo-v6.svg" alt="logo" />
            </div>
            <div className="form-login">
              <h1>Xin chào!</h1>
              {/* <div className="sub-des">Lorem ipsum dolor sit amet</div> */}
              <Form form={form} onFinish={onSubmit}>
                <div className="input-login-item">
                  <div className="label-input">Tài khoản</div>
                  <Form.Item name="username">
                    <Input
                      name="username"
                      value={username}
                      onChange={handleChange}
                      placeholder="Nhập tài khoản"
                      className="form-input"
                      required
                      size="large"
                    />
                  </Form.Item>
                </div>
                <div className="input-login-item">
                  <div className="label-input">Mật khẩu</div>
                  <Input.Password
                    value={password}
                    name="password"
                    onChange={handleChange}
                    placeholder="Nhập mật khẩu"
                    className="form-input"
                    iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                    required
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <Checkbox activeColor="#00BA34" onChange={onChange}><div className="remember-me">Remember me</div></Checkbox>
                  </div>
                  <a
                    role="button"
                    className="forget-password"
                    tabIndex="0"
                    onClick={handleForgotPassword}
                  >
                    Quên mật khẩu?
                  </a>
                </div>
                <button type="submit" className="btn-login">
                  Đăng nhập
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ModalChangePassword
        isOpen={isOpenModalChangePassword}
        setIsOpen={setIsOpenModalChangePassword}
        onSuccess={handleShowChangePasswordSuccesfully}
      />
      <ModalOTPInput
        isOpen={isOpenModalOtp}
        setIsOpen={setIsOpenModalOtp}
        last4digit={last4digit}
        isResendable={isResendable}
        setIsResendable={setIsResendable}
        getOTP={getOTP}
        confirmOtp={confirmOtp}
        otp={otp}
        setOtp={setOtp}
        expired_at={expired_at}
      />
      <ModalChangeAndForgotPassword
        isOpen={isOpenModalChangeAndForgotPassword}
        setIsOpen={setIsOpenModalChangeAndForgotPassword}
        setIsOpenModalOtp={setIsOpenModalOtp}
        dataForgotPassword={dataForgotPassword}
        setDataForgotPassword={setDataForgotPassword}
        isForgot
      />
    </div>
  )
}
export default LoginPage
