import {
  Button, Collapse, DatePicker, Form, Input, InputNumber, message, Modal, Select, Space, Spin,
} from 'antd'
import { CaretRightOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react'
import dayjs from 'dayjs'
import UploadImageComponent from '../../../components/common/uploadImage'
import ExternalAccountApis from '../../../apis/externalAccountApis'
import {
  COMMISSION_PRODUCT,
  DEFAULT_IMAGE,
  DEFAULT_PASSWORD,
  regexPhoneNumber,
  ROLES_LIST,
} from '../../../constants/constant'
import { getErrorMessagesAccountPage } from '../../../util/errorMappingAccount'
import { roleExists } from '../../../util'
import ImageArrayUpload from '../../../components/common/imageArrayUpload'

const getTextTag = {
  suspend: 'Tạm ngừng',
  active: 'Hoạt động',
  rejected: 'Từ chối',
}

const ExternalAccountForm = forwardRef((props, ref) => {
  const {
    getListExternalAccounts,
    detailID,
    isLoading,
    detailData,
    handleCloseDrawer,
    isEdit,
    setIsEdit,
    setSaving,
    externalRoles,
    sellers,
    isOpenDrawer,
    isProfile,
  } = props
  const [createForm] = Form.useForm()
  const [avatarUrl, setAvatarUrl] = useState(DEFAULT_IMAGE)
  const [panelKeys, setPanelKeys] = useState(['1', '2', '3'])
  const [disableForm, setDisableForm] = useState(false)

  const [selectCommissions, setSelectCommissions] = useState(COMMISSION_PRODUCT)
  const [optionsCommissions, setOptionsCommissions] = useState(COMMISSION_PRODUCT)
  const [fileAddressImageList, setFileAddressImageList] = useState([])

  const [listProvince, setListProvince] = useState([])
  const [listDistrict, setListDistrict] = useState([])
  const [listWard, setListWard] = useState([])

  const handleSelectCommissions = (index, selectedValue) => {
    const currentValues = createForm.getFieldValue('commissions') || []
    const updatedValues = [...currentValues]
    updatedValues[index].product_type = selectedValue
    createForm.setFieldsValue({ commissions: updatedValues })
    const selectedOptions = updatedValues.map((value) => value?.product_type)
    const remainingOptions = optionsCommissions.filter(
      (option) => !selectedOptions.includes(option.value),
    )
    setSelectCommissions(remainingOptions)
  }

  const handleRemoveCommission = (index) => {
    const values = createForm.getFieldValue('commissions') || []
    values.splice(index, 1)
    createForm.setFieldsValue({ commissions: values })
    const selectedOptions = values.map((item) => item?.product_type)
    const remainingOptions = optionsCommissions.filter(
      (option) => !selectedOptions.includes(option.value),
    )
    setSelectCommissions(remainingOptions)
  }

  useEffect(() => {
    if (avatarUrl) {
      createForm.setFieldValue('avatar_url', avatarUrl)
    }
  }, [avatarUrl])

  const handleCloseDrawerCreate = () => {
    setAvatarUrl('')
    setFileAddressImageList([])
    createForm.resetFields()
    handleCloseDrawer()
  }

  const fetchProvince = async () => {
    const res = await ExternalAccountApis.getProvince()
    setListProvince(res?.data?.data ?? [])
  }

  useEffect(() => {
    fetchProvince()
  }, [])

  const fetchDistrict = async (provinceID) => {
    if (provinceID) {
      const res = await ExternalAccountApis.getDistrict(provinceID)
      setListDistrict(res?.data?.data ?? [])
    }
  }

  const fetchWard = async (provinceID, districtID) => {
    if (provinceID && districtID) {
      const res = await ExternalAccountApis.getWard(provinceID, districtID)
      setListWard(res?.data?.data ?? [])
    }
  }

  const handleSelectProvince = (value) => {
    createForm.setFieldValue('district_id', null)
    createForm.setFieldValue('ward_id', null)
    setListDistrict([])
    setListWard([])
    fetchDistrict(value)
  }

  const handleSelectDistrict = (value) => {
    createForm.setFieldValue('ward_id', null)
    setListWard([])
    fetchWard(createForm.getFieldValue('province_id'), value)
  }

  const patchDataToForm = (detailRawData) => {
    const detailMappingData = {
      full_name: detailRawData?.full_name,
      display_name: detailRawData?.display_name,
      username: detailRawData?.username,
      email: detailRawData?.email,
      phone_number: detailRawData?.phone_number,
      gender: detailRawData?.gender,
      identity_card: detailRawData?.identity_card,
      joined_at: detailRawData?.joined_at
        ? dayjs(detailRawData?.joined_at)
        : null,
      dob: detailRawData?.dob ? dayjs(detailRawData?.dob) : null,
      address: detailRawData?.address,
      role_id: detailRawData?.roles?.[0]?.name,
      seller_id: detailRawData?.seller?.full_name,
      note: detailRawData?.note,
      commissions: [...(detailRawData?.commissions || [])],
      status: detailRawData?.status,
      account_id: detailRawData?.account_id,
      province_id: detailData?.address_ward?.district?.province?.id,
      district_id: detailData?.address_ward?.district?.id,
      ward_id: detailData?.address_ward?.id,
      bank: detailData?.bank,
      branch_name: detailData?.branch_name,
      account_owner: detailData?.account_owner,
      account_number: detailData?.account_number,
    }
    createForm.setFieldsValue(detailMappingData)
    fetchDistrict(detailData?.address_ward?.district?.province?.id)
    fetchWard(
      detailData?.address_ward?.district?.province?.id,
      detailData?.address_ward?.district?.id,
    )
    setFileAddressImageList(
      (detailRawData?.address_image_url || []).map((x) => ({ url: x })),
    )
    setAvatarUrl(
      detailRawData?.avatar_url
        || 'https://statics-dev.simplus.space/image/64390d62-98a0-4c92-b9a5-d011c8671831.jpeg',
    )
  }

  useEffect(() => {
    if (detailData) {
      patchDataToForm(detailData)
    }
  }, [detailData])

  useEffect(() => {
    if (detailID) {
      setDisableForm(!isEdit)
    }
  }, [isEdit])

  const createExternalAccount = async () => {
    setSaving(true)
    try {
      const account = {
        ...createForm.getFieldsValue(),
        username: createForm.getFieldsValue()?.username?.trim(),
        avatar_url: avatarUrl,
        address_image_url: [...fileAddressImageList.map((x) => x?.url)],
      }
      await ExternalAccountApis.createExternalAccount(account)
      Modal.success({
        title: 'Đã tạo tài khoản thành công',
        content: (
          <div>
            <div>
              Tên tài khoản:
              <b>{` ${account?.username}`}</b>
            </div>
            <div>
              Mật khẩu:
              <b>{` ${DEFAULT_PASSWORD}`}</b>
            </div>
          </div>
        ),
        okButtonProps: {
          style: {
            backgroundColor: '#00BA34',
          },
        },
      })
      handleCloseDrawerCreate()
    } catch (error) {
      const errorMapping = getErrorMessagesAccountPage(
        error?.response?.data?.message,
      )
      message.error(errorMapping || 'Tạo tài khoản thất bại')
    } finally {
      setSaving(false)
    }
  }

  const editExternalAccount = async () => {
    setSaving(true)
    try {
      const account = {
        ...createForm.getFieldsValue(),
        username: createForm.getFieldsValue()?.username?.trim(),
        avatar_url: avatarUrl,
        address_image_url: [...fileAddressImageList.map((x) => x?.url)],
      }
      delete account.account_id
      delete account.username
      delete account.phone_number
      delete account.role_id
      if (detailData?.identity_card === account?.identity_card) {
        delete account.identity_card
      }
      if (detailData?.email === account?.email) {
        delete account.email
      }
      await ExternalAccountApis.editExternalAccount(account, detailID)
      message.success('Chỉnh sửa tài khoản thành công')
      setIsEdit(false)
    } catch (error) {
      const errorMapping = getErrorMessagesAccountPage(
        error?.response?.data?.message,
      )
      message.error(errorMapping || 'Chỉnh sửa tài khoản thất bại')
    } finally {
      setSaving(false)
    }
  }

  const onFinishCreateForm = async () => {
    if (isEdit) {
      await editExternalAccount()
    } else {
      await createExternalAccount()
    }
    getListExternalAccounts()
  }

  const handleCollapseChange = (keys) => {
    setPanelKeys(keys)
  }

  useImperativeHandle(ref, () => ({
    createExternalAccount,
    createForm,
    patchDataToForm,
  }))

  useEffect(() => {
    if (!isOpenDrawer) {
      setAvatarUrl('')
      setFileAddressImageList([])
      createForm.resetFields()
    }
  }, [isOpenDrawer])

  return (
    <>
      {isLoading ? (
        <div className="loading-screen">
          <Spin />
        </div>
      ) : (
        <>
          <div className="account-avatar">
            <UploadImageComponent
              withoutLogo
              imageUrl={avatarUrl}
              setImage={setAvatarUrl}
              validate
              isEditing={!detailID || isEdit}
              onImageError={(e) => {
                e.target.src = DEFAULT_IMAGE
              }}
              minHeight={160}
            />
          </div>

          {detailID && (
            <>
              <div className="account-fullname">
                {createForm.getFieldValue('full_name')}
              </div>
              <div className="account-status">
                <div className="tag-wrapper">
                  <div
                    className={`tag tag-${createForm
                      .getFieldValue('status')
                      ?.toLocaleLowerCase()}`}
                  >
                    {
                      getTextTag[
                        createForm.getFieldValue('status')?.toLocaleLowerCase()
                      ]
                    }
                  </div>
                </div>
              </div>
            </>
          )}
          {detailData?.status === 'suspend' && detailData?.deactivate_note && (
            <div className="account-reason">
              <div className="account-reason-title">
                Lý do:
                <span className="account-reason-content">
                  {detailData?.deactivate_note}
                </span>
              </div>
            </div>
          )}
          {detailData?.status === 'rejected' && detailData?.rejected_note && (
            <div className="account-reason">
              <div className="account-reason-title">
                Lý do:
                <span className="account-reason-content">
                  {detailData?.rejected_note}
                </span>
              </div>
            </div>
          )}
          <Form
            layout="vertical"
            form={createForm}
            onFinishFailed={() => setPanelKeys(['1', '2'])}
            onFinish={onFinishCreateForm}
            disabled={disableForm}
          >
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              activeKey={panelKeys}
              onChange={handleCollapseChange}
            >
              <Collapse.Panel header="Thông tin cá nhân" key="1">
                <div className="form-divide">
                  <div className="child-form">
                    {!detailID ? (
                      <Form.Item
                        name="full_name"
                        label="Họ tên"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập họ tên',
                          },
                        ]}
                      >
                        <Input placeholder="Nhập họ tên" />
                      </Form.Item>
                    ) : (
                      <Form.Item name="account_id" label="ID">
                        <Input placeholder="ID" disabled />
                      </Form.Item>
                    )}
                  </div>
                  <div className="child-form">
                    <Form.Item
                      name="username"
                      label="Tên tài khoản"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập tên tài khoản',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Nhập tên tài khoản"
                        disabled={detailID}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="form-divide">
                  <div className="child-form">
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          type: 'email',
                          message: 'Địa chỉ email không hợp lệ',
                        },
                      ]}
                    >
                      <Input placeholder="Nhập địa chỉ email" />
                    </Form.Item>
                  </div>
                  <div className="child-form">
                    <Form.Item
                      label="Số điện thoại"
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số điện thoại',
                        },
                        {
                          pattern: regexPhoneNumber,
                          message: 'Vui lòng nhập đúng số điện thoại!',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Nhập số điện thoại"
                        disabled={detailID}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="form-divide">
                  <div className="child-form">
                    <Form.Item label="Ngày sinh" name="dob">
                      <DatePicker format="DD/MM/YYYY" size="large" />
                    </Form.Item>
                  </div>
                  <div className="child-form">
                    <Form.Item
                      label="Giới tính"
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn giới tính',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Chọn giới tính"
                        size="large"
                        onChange={(value) => {
                          createForm.setFieldValue('gender', value)
                        }}
                      >
                        <Select.Option value="male">Nam</Select.Option>
                        <Select.Option value="female">Nữ</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="form-divide">
                  <div className="child-form">
                    <Form.Item
                      label="Số CMND/CCCD"
                      name="identity_card"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập Số CMND/CCCD',
                        },
                        {
                          min: 9,
                          message: 'Vui lòng nhập đủ Số CMND/CCCD',
                        },
                        {
                          max: 12,
                          message: 'Vui lòng nhập đủ Số CMND/CCCD',
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: 'Vui lòng nhập đúng định dạng Số CMND/CCCD',
                        },
                      ]}
                    >
                      <Input placeholder="Nhập số CMND/CCCD" />
                    </Form.Item>
                  </div>
                  <div className="child-form">
                    <Form.Item
                      label="Loại tài khoản"
                      name="role_id"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn loại tài khoản',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Chọn loại tài khoản"
                        size="large"
                        disabled={detailID}
                      >
                        {externalRoles.map((role) => (
                          <Select.Option key={role?.id} value={role?.id}>
                            {role?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="form-divide">
                  <div className="child-form">
                    <Form.Item
                      label="Địa chỉ"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập địa chỉ',
                        },
                      ]}
                    >
                      <Input placeholder="Nhập địa chỉ" />
                    </Form.Item>
                  </div>
                  <div className="child-form">
                    <Form.Item
                      label="Tỉnh/ Thành Phố"
                      name="province_id"
                      rules={[
                        {
                          required: true,
                          message: 'Chọn Tỉnh/ Thành Phố',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) => option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0}
                        allowClear
                        placeholder="Chọn Tỉnh/ Thành Phố"
                        size="large"
                        onChange={(value) => handleSelectProvince(value)}
                      >
                        {(listProvince || []).map((province) => (
                          <Select.Option
                            key={province?.id}
                            value={province?.id}
                          >
                            {province?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="form-divide">
                  <div className="child-form">
                    <Form.Item
                      label="Quận/ Huyện"
                      name="district_id"
                      rules={[
                        {
                          required: true,
                          message: 'Chọn Quận/ Huyện',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) => option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0}
                        placeholder="Chọn Quận/ Huyện"
                        size="large"
                        allowClear
                        onChange={(value) => handleSelectDistrict(value)}
                      >
                        {(listDistrict || []).map((district) => (
                          <Select.Option
                            key={district?.id}
                            value={district?.id}
                          >
                            {district?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="child-form">
                    <Form.Item
                      label="Phường/ Xã"
                      name="ward_id"
                      rules={[
                        {
                          required: true,
                          message: 'Chọn Phường/ Xã',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Chọn Phường/ Xã"
                        size="large"
                        showSearch
                        allowClear
                        optionFilterProp="name"
                        filterOption={(input, option) => option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0}
                      >
                        {(listWard || []).map((ward) => (
                          <Select.Option key={ward?.id} value={ward?.id}>
                            {ward?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="form-divide">
                  <div className="child-form">
                    <Form.Item label="Ngày gia nhập" name="joined_at">
                      <DatePicker format="DD/MM/YYYY" size="large" />
                    </Form.Item>
                  </div>
                  <div className="child-form">
                    <Form.Item
                      label="Seller phụ trách"
                      name="seller_id"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn seller phụ trách',
                        },
                      ]}
                      // initialValue={get(KEYS.USER) ? get(KEYS.USER).id : null}
                    >
                      <Select
                        placeholder="Chọn seller phụ trách"
                        size="large"
                        // defaultValue={get(KEYS.USER) ? get(KEYS.USER).id : null}
                        showSearch
                        optionFilterProp="username"
                        filterOption={(input, option) => option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0}
                      >
                        {sellers.map((seller) => (
                          <Select.Option key={seller?.id} value={seller?.id}>
                            {`${seller?.full_name} - ${seller?.username}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <Form.Item label="Ghi chú" name="note">
                  <Input.TextArea rows={2} placeholder="Nhập ghi chú" />
                </Form.Item>

                <Form.Item label="Ảnh hồ sơ tài khoản" name="address_image_url">
                  <ImageArrayUpload
                    isDisable={detailID && !isEdit}
                    maxLength="5"
                    fileList={fileAddressImageList}
                    setFileList={setFileAddressImageList}
                  />
                </Form.Item>
              </Collapse.Panel>
              <Collapse.Panel header="Thông tin thanh toán" key="2">
                <div className="form-divide">
                  <div className="child-form">
                    <Form.Item label="Ngân hàng" name="bank">
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="child-form">
                    <Form.Item label="Chi nhánh" name="branch_name">
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className="form-divide">
                  <div className="child-form">
                    <Form.Item label="Chủ tài khoản" name="account_owner">
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="child-form">
                    <Form.Item label="Số tài khoản" name="account_number">
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Collapse.Panel>
              {(roleExists([
                ROLES_LIST.SUPER_ADMIN,
                ROLES_LIST.MANAGING_ADMIN,
                ROLES_LIST.FINANCE_ADMIN,
                ROLES_LIST.BUSINESS_MANAGER,
                ROLES_LIST.TECHNICAL_MANAGER,
                ROLES_LIST.CUSTOMER_SERVICE_MANAGER,
                ROLES_LIST.ADMINISTRATIVE_MANAGER,
              ]) || isProfile) && (
                <Collapse.Panel header="Thông tin hoa hồng" key="3">
                  <Form.List name="commissions">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Space key={field.key} align="baseline">
                            <Form.Item
                              //     noStyle
                              //     shouldUpdate={(prevValues, curValues) => prevValues.commission_rate
                              //   !== curValues.commission_rate
                              // || prevValues.product_type
                              //   !== curValues.product_type}
                              noStyle
                              shouldUpdate={() => true}
                            >
                              {() => (
                                <Form.Item
                                  {...field}
                                  label="Loại sản phẩm"
                                  name={[field.name, 'product_type']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Vui lòng chọn loại sản phẩm',
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    style={{ width: '100%' }}
                                    disabled={detailID && !isEdit}
                                    onChange={(value) => handleSelectCommissions(index, value)}
                                  >
                                    {optionsCommissions.map((item) => (
                                      <Select.Option
                                        key={item?.value}
                                        disabled={
                                          selectCommissions.findIndex(
                                            (x) => x.value === item.value,
                                          ) === -1
                                        }
                                      >
                                        {item?.label}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              )}
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label="Hoa hồng"
                              name={[field.name, 'commission_rate']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Vui lòng nhập hoa hồng',
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                min={0}
                                max={100}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace('%', '')}
                              />
                            </Form.Item>
                            <div>
                              <MinusCircleOutlined
                                onClick={() => handleRemoveCommission(index)}
                                style={{
                                  cursor: (detailID && !isEdit) ? 'not-allowed' : 'pointer',
                                  pointerEvents: (detailID && !isEdit) ? 'none' : 'auto',
                                }}
                              />
                            </div>
                          </Space>
                        ))}
                        {fields?.length < COMMISSION_PRODUCT.length
                          && (!detailID || isEdit) && (
                            <Form.Item>
                              <Button
                                className="btn-add"
                                type="text"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Thêm thông tin hoa hồng
                              </Button>
                            </Form.Item>
                        )}
                      </>
                    )}
                  </Form.List>
                </Collapse.Panel>
              )}
            </Collapse>
          </Form>
        </>
      )}
    </>
  )
})

export default ExternalAccountForm
